export type SupportedLocales = "pt" | "en";

export type SupportedMessagesIds = keyof typeof MessagesIds;

export enum MessagesIds {
  LINK_HOME = "LINK_HOME",
  LINK_PORTFOLIO = "LINK_PORTFOLIO",
  MAIN_MESSAGE = "MAIN_MESSAGE",
  MAIN_DESCRIPTION = "MAIN_DESCRIPTION",

  MY_SKILLS = "MY_SKILLS",
  MY_SKILLS_BEGINNER = "MY_SKILLS_BEGINNER",
  MY_SKILLS_INTERMEDIATE = "MY_SKILLS_INTERMEDIATE",
  MY_SKILLS_ADVANCED = "MY_SKILLS_ADVANCED",

  MAIN_PROJECTS = "MAIN_PROJECTS",
  WANNA_KNOW_MORE = "WANNA_KNOW_MORE",
  RESUME_CALL = "RESUME_CALL",
  DOWNLOAD_RESUME = "DOWNLOAD_RESUME",

  UNIT_TESTS = "UNIT_TESTS",

  GET_IN_TOUCH = "GET_IN_TOUCH",
  FOOTER_SOCIAL_CALL = "FOOTER_SOCIAL_CALL",
  WHERE_TO_FIND_ME = "WHERE_TO_FIND_ME",
  DRAWER_DESCRIPTION = "DRAWER_DESCRIPTION",
  DRAWER_SOCIAL = "DRAWER_SOCIAL",

  FILTER_LABEL = "FILTER_LABEL",
  FILTER_SHOW_ALL = "FILTER_SHOW_ALL",

  PROJECT_SHARE = "PROJECT_SHARE",
  PROJECT_DETAILS = "PROJECT_DETAILS",
  PROJECT_DATE = "PROJECT_DATE",
  PROJECT_TAGS = "PROJECT_TAGS",

  //? WORK EXPERIENCES

  WORK_EXPERIENCE = "WORK_EXPERIENCE",
  WORK_EXPERIENCE_01_TITLE = "WORK_EXPERIENCE_01_TITLE",
  WORK_EXPERIENCE_01_DESCRIPTION = "WORK_EXPERIENCE_01_DESCRIPTION",
  WORK_EXPERIENCE_02_TITLE = "WORK_EXPERIENCE_02_TITLE",
  WORK_EXPERIENCE_02_DESCRIPTION = "WORK_EXPERIENCE_02_DESCRIPTION",
  WORK_EXPERIENCE_03_TITLE = "WORK_EXPERIENCE_03_TITLE",
  WORK_EXPERIENCE_03_DESCRIPTION = "WORK_EXPERIENCE_03_DESCRIPTION",
  //? PROJECTS

  PROJECT_01_NAME = "PROJECT_01_NAME",
  PROJECT_01_DETAILS = "PROJECT_01_DETAILS",
  PROJECT_01_DESCRIPTION = "PROJECT_01_DESCRIPTION",

  PROJECT_02_NAME = "PROJECT_02_NAME",
  PROJECT_02_DETAILS = "PROJECT_02_DETAILS",
  PROJECT_02_DESCRIPTION = "PROJECT_02_DESCRIPTION",

  //

  RESUME_FILENAME = "RESUME_FILENAME",
}

export type LocaleObject = { [property in SupportedMessagesIds]: string };
